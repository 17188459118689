<template
  ><div style="width: 100%;" @click="handleClear">
    <el-button
      :disabled="!this.canWrite()"
      class="createBtn"
      @click.stop="createContentItem"
      >{{ __("Create XSI Profile") }}</el-button
    >
    <div style="width: 100%">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :content-api="contentAPI"
            :show-slot="true"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar :native="true">
          <div class="contentListContainer">
            <el-table
              ref="xsiProfilesTable"
              v-show="this.canRead()"
              v-loading="loading"
              :data="xsi_profiles"
              @row-click="handleSingleClick"
              @row-dblclick="handleDoubleClick"
              @current-change="handleCurrentChange"
              highlight-current-row
              class="list-table"
            >
              <el-table-column :label="__('Profile Name')">
                <template slot-scope="scope">
                  <span>{{ scope.row.xsi_profile_name }}</span>
                </template>
              </el-table-column>

              <el-table-column :label="__('Broadsoft Username')">
                <template slot-scope="scope">
                  <span>{{ scope.row.xsi_user_id }}</span>
                </template>
              </el-table-column>

              <el-table-column :label="__('XSI Event URL')">
                <template slot-scope="scope">
                  <span>{{ scope.row.xsi_event_url }}</span>
                </template>
              </el-table-column>

              <el-table-column :label="__('Account Type')">
                <template slot-scope="scope">
                  <span>{{ scope.row.xsi_account_type }}</span>
                </template>
              </el-table-column>

              <el-table-column
                :label="__('Subscription Mode')"
                style="width: 50px!important;"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.xsi_subscription_mode }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "XSIProfilesManageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar
  },
  data() {
    return {
      search: "",
      filters: ["xsi_profile_id"],
      sortByColumns: ["xsi_profile_name", "xsi_profile_id"],
      currentRow: ""
    };
  },
  computed: {
    ...mapState("xsiprofiles", {
      xsi_profiles: state => state.xsi_profiles,
      loading: state => state.isLoading
    })
  },
  methods: {
    ...mapActions("xsiprofiles", {
      contentAPI: "getXsiProfiles",
      deleteContentMethod: "deleteXsiProfile",
      undoDeleteContent: "undoDeleteContent"
    }),
    handleClear() {
      this.$refs.xsiProfilesTable.setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== undefined && val !== null && val !== -1) {
          this.$refs.xsiProfilesTable &&
            this.$refs.xsiProfilesTable.setCurrentRow(this.xsi_profiles[val]);
        } else if (val === -1) {
          this.$refs.xsiProfilesTable &&
            this.$refs.xsiProfilesTable.setCurrentRow();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$--color-node-hover-default: var(--theme-hover-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>
