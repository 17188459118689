<script>
import { mapState } from "vuex";
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";

export default {
  name: "XSIProfilesManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("xsiprofiles", {
      contents: state => state.xsi_profiles
    })
  },

  methods: {
    initContent() {
      return {
        xsi_profile_name: "",
        xsi_user_id: "",
        xsi_password: "",
        xsi_action_url: "",
        xsi_event_url: "",
        xsi_account_type: "user",
        xsi_subscription_mode: "http",
        xsi_enterprise_id: "",
        xsi_group_id: ""
      };
    }
  },
  mounted() {
    this.contentType = "XSI Profile";
    this.primaryKey = "xsi_profile_id";
    this.scope = "ac";
  }
};
</script>
<style lang="scss" scoped></style>
