<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Profile Name") }}</div>
    <div class="details">{{ contentFormInfo.xsi_profile_name }}</div>
    <div class="titles">{{ __("Broadsoft User Name") }}</div>
    <div class="details">{{ contentFormInfo.xsi_user_id }}</div>
    <div class="titles">{{ __("Xsi Action Base URL") }}</div>
    <div class="details">{{ contentFormInfo.xsi_action_url }}</div>
    <div class="titles">{{ __("Xsi Event Base URL") }}</div>
    <div class="details">{{ contentFormInfo.xsi_event_url }}</div>
    <div class="titles">{{ __("Account Type") }}</div>
    <div class="details">{{ contentFormInfo.xsi_account_type }}</div>
    <div class="titles">{{ __("Subscription Mode") }}</div>
    <div class="details">{{ contentFormInfo.xsi_subscription_mode }}</div>
    <div v-if="contentFormInfo.xsi_account_type === 'enterprise'">
      <div class="titles">{{ __("Enterprise ID") }}</div>
      <div class="details">{{ contentFormInfo.xsi_enterprise_id }}</div>
    </div>
    <div v-if="contentFormInfo.xsi_account_type === 'group'">
      <div class="titles">{{ __("Service Provider ID") }}</div>
      <div class="details">{{ contentFormInfo.xsi_enterprise_id }}</div>
      <div class="titles">{{ __("Group ID") }}</div>
      <div class="details">{{ contentFormInfo.xsi_group_id }}</div>
    </div>

    <el-button
      :disabled="!this.canWrite()"
      @click="handleEdit"
      class="editBtn"
      >{{ __("Edit") }}</el-button
    >
    <el-button
      :disabled="!this.canWrite()"
      @click="handleDelete(contentFormInfo)"
      class="deleteBtn"
      >{{ __("Delete") }}
    </el-button>
    <el-dialog
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isLoading"
    >
      <el-scrollbar :native="false" style="margin-top: 34px">
        <div style="max-height: calc(100vh - 34px);">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="12" :offset="6">
                <page-header :title="__('XSI Profile')" :contentId="id" />
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Profile Name')"
                  prop="xsi_profile_name"
                >
                  <el-input v-model="contentForm.xsi_profile_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Broadsoft Username')"
                  prop="xsi_user_id"
                >
                  <el-input
                    v-model="contentForm.xsi_user_id"
                    autocomplete="new-password"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Broadsoft Password')"
                  prop="xsi_password"
                >
                  <el-input
                    v-model="contentForm.xsi_password"
                    type="password"
                    autocomplete="new-password"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('XSI Action Base URL')"
                  prop="xsi_action_url"
                >
                  <el-input v-model="contentForm.xsi_action_url"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('XSI Event Base Base URL')"
                  prop="xsi_event_url"
                >
                  <el-input v-model="contentForm.xsi_event_url"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Account Type')"
                  prop="xsi_account_type"
                >
                  <el-radio-group
                    v-model="contentForm.xsi_account_type"
                    class="display-inline"
                  >
                    <el-radio label="user">{{ __("User") }}</el-radio>
                    <el-radio label="enterprise">{{
                      __("Enterprise")
                    }}</el-radio>
                    <el-radio label="group">{{ __("Group") }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <div v-if="contentForm.xsi_account_type === 'enterprise'">
              <el-row type="flex">
                <el-col :span="12" :offset="6">
                  <el-form-item
                    :label="__('Enterprise ID')"
                    prop="xsi_enterprise_id"
                    :required="contentForm.xsi_account_type === 'enterprise'"
                  >
                    <el-input
                      v-model="contentForm.xsi_enterprise_id"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <div v-if="contentForm.xsi_account_type === 'group'">
              <el-row type="flex">
                <el-col :span="12" :offset="6">
                  <el-form-item
                    :label="__('Service Provider ID')"
                    prop="xsi_enterprise_id"
                    :required="contentForm.xsi_account_type === 'group'"
                  >
                    <el-input
                      v-model="contentForm.xsi_enterprise_id"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex">
                <el-col :span="12" :offset="6">
                  <el-form-item
                    :label="__('Group ID')"
                    prop="xsi_group_id"
                    :required="contentForm.xsi_account_type === 'group'"
                  >
                    <el-input v-model="contentForm.xsi_group_id"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Subscription Mode')"
                  prop="xsi_subscription_mode"
                >
                  <el-radio-group
                    v-model="contentForm.xsi_subscription_mode"
                    class="display-inline"
                  >
                    <el-radio label="http">Http</el-radio>
                    <el-radio label="channel">Channel</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              v-show="this.canWrite()"
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :primaryKey="id"
            />
            <el-button @click="handleCancelBtn" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton
  },
  data() {
    let validateXsiActionURL = async (rule, value, callback) => {
      if (
        !this.validURL(this.contentForm.xsi_action_url) &&
        this.contentForm.xsi_action_url !== ""
      ) {
        callback(__("XSI Action Base URL is not valid"));
      } else {
        callback();
      }
    };
    let validateXsiEventURL = async (rule, value, callback) => {
      if (
        !this.validURL(this.contentForm.xsi_event_url) &&
        this.contentForm.xsi_event_url !== ""
      ) {
        callback(__("XSI Event Base URL is not valid"));
      } else {
        callback();
      }
    };
    let validateEnterpriseId = (rule, value, callback) => {
      if (
        this.contentForm.xsi_account_type === "enterprise" &&
        this.contentForm.xsi_enterprise_id === ""
      ) {
        callback(__("Enterprise ID is required"));
      } else if (
        this.contentForm.xsi_account_type === "group" &&
        this.contentForm.xsi_enterprise_id === ""
      ) {
        callback(__("Service Provider ID is required"));
      } else {
        callback();
      }
    };
    let validateGroupId = (rule, value, callback) => {
      if (
        this.contentForm.xsi_account_type === "group" &&
        this.contentForm.xsi_group_id === ""
      ) {
        callback(__("Group ID is required"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        xsi_profile_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Profile name is required")
          }
        ],
        xsi_user_id: [
          {
            required: true,
            trigger: "blur",
            message: __("User name is required")
          }
        ],
        xsi_password: [
          {
            required: true,
            trigger: "blur",
            message: __("Password is required")
          }
        ],
        xsi_action_url: [
          {
            required: true,
            trigger: "blur",
            message: __("Action Base URL is required")
          },
          { validator: validateXsiActionURL, trigger: "blur" }
        ],
        xsi_event_url: [
          {
            required: true,
            trigger: "blur",
            message: __("Event Base URL is required")
          },
          { validator: validateXsiEventURL, trigger: "blur" }
        ],
        xsi_account_type: [
          {
            required: true,
            trigger: "blur",
            message: __("Account type is required")
          }
        ],
        xsi_subscription_mode: [
          {
            required: true,
            trigger: "blur",
            message: __("Subscription Mode is required")
          }
        ],
        xsi_enterprise_id: [
          {
            required: false,
            trigger: "blur",
            validator: validateEnterpriseId
          }
        ],
        xsi_group_id: [
          {
            required: false,
            trigger: "blur",
            validator: validateGroupId
          }
        ]
      }
    };
  },

  computed: {
    ...mapState("xsiprofiles", {
      isLoading: state => state.isLoading
    })
  },

  methods: {
    ...mapActions("xsiprofiles", {
      createXsiProfile: "createXsiProfile",
      updateXsiProfile: "updateXsiProfile",
      deleteContentMethod: "deleteXsiProfile",
      undoDeleteContent: "undoDeleteContent"
    }),
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process =
            this.id === -1 ? this.createXsiProfile : this.updateXsiProfile;

          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("XSI Profile created successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("XSI Profile updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.response.data.message
              });
            });
        }
      });
    },
    handleCancelBtn() {
      this.resetForm("contentForm");
      this.handleCancel();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    validURL(str) {
      let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
